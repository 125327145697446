import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/juber.jpg';

const AdvocateJuberDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Mr. Hussain did his LLB from Damodaram Sanjeevayya National Law
                University. He enrolled as an Advocate in the year 2020. He is
                specialised in the areas of Corporate Laws, Civil Laws ,
                Arbitrations and Family Laws. He is currently practising at
                National Company Law Tribunal, Arbitrations, Civil and Criminal
                Courts at Hyderabad.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>
                  Mr. S.A.Juber Hussain. B.A. LLB. (HONS).
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateJuberDetails;
